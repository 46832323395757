import {classNames} from '@shopify/css-utilities';
import {useCallback, useEffect, useRef, useState} from 'preact/hooks';

import {AuthorizeIframe} from '~/components/AuthorizeIframe';
import {ShopIcon} from '~/components/ShopIcon';
import {useBugsnag} from '~/foundation/Bugsnag/hooks';
import {useI18n} from '~/foundation/I18n/hooks';
import {useMonorail} from '~/foundation/Monorail/hooks';
import {RootProvider} from '~/foundation/RootProvider/RootProvider';
import type {CustomElementNodeProps} from '~/foundation/RootProvider/types';
import {useAuthorizeUrl} from '~/hooks/useAuthorizeUrl';
import {useDispatchEvent} from '~/hooks/useDispatchEvent';
import {useStorage} from '~/hooks/useStorage';
import {useStorefrontOrigin} from '~/hooks/useStorefrontOrigin';
import type {SdkErrorCode} from '~/types/authorize';
import type {AuthorizeErrorEvent, CompletedEvent} from '~/types/event';
import type {IframeElement} from '~/types/iframe';
import {isIntersectionObserverSupported} from '~/utils/browser';
import {exchangeLoginCookie} from '~/utils/cookieExchange';
import register from '~/utils/register';

import {FollowingModal} from './components/FollowingModal';
import {HeartIcon} from './components/HeartIcon';

export const STORAGE_KEY = 'following';

export interface ShopFollowButtonProps extends CustomElementNodeProps {
  clientId: string;
  devMode?: boolean;
  storefrontOrigin?: string;
}

const FollowInternal = ({
  clientId,
  devMode = false,
  storefrontOrigin: providedStorefrontOrigin,
}: ShopFollowButtonProps) => {
  const {trackPageImpression, trackUserAction} = useMonorail();
  const {translate} = useI18n();
  const dispatchEvent = useDispatchEvent();
  const {notify} = useBugsnag();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const iframeRef = useRef<IframeElement | null>(null);
  const [following, setFollowing] = useStorage({
    key: STORAGE_KEY,
    defaultValue: false,
  });
  const [followingModalVisible, setFollowingModalVisible] = useState(false);
  const [receivedUserInteraction, setReceivedUserInteraction] = useState(false);
  const storefrontOrigin = useStorefrontOrigin(providedStorefrontOrigin);
  const [error, setError] = useState<SdkErrorCode>();

  useEffect(() => {
    trackPageImpression({
      page: following
        ? 'COMPONENT_LOADED_FOLLOWING'
        : 'COMPONENT_LOADED_NOT_FOLLOWING',
    });
  }, [following, trackPageImpression]);

  useEffect(() => {
    if (
      !buttonRef.current ||
      trackPageImpression === undefined ||
      !isIntersectionObserverSupported()
    ) {
      return;
    }

    const buttonInViewportObserver = new IntersectionObserver((entries) => {
      for (const {isIntersecting} of entries) {
        if (isIntersecting) {
          buttonInViewportObserver?.disconnect();
          trackPageImpression({
            page: 'FOLLOW_BUTTON_SHOWN_IN_VIEWPORT',
          });
        }
      }
    });

    buttonInViewportObserver.observe(buttonRef.current);

    return () => {
      buttonInViewportObserver.disconnect();
    };
  }, [trackPageImpression]);

  const handleButtonClick = useCallback(() => {
    if (following) {
      trackPageImpression({
        page: 'FOLLOWING_GET_SHOP_APP_CTA',
      });
      return setFollowingModalVisible(true);
    }

    trackUserAction({
      userAction: 'FOLLOW_ON_SHOP_CLICKED',
    });

    if (devMode) {
      return setFollowing(true);
    }

    setReceivedUserInteraction(true);
    iframeRef.current?.open('user_button_clicked');
  }, [devMode, following, setFollowing, trackPageImpression, trackUserAction]);

  async function handleCompleted({
    loggedIn,
    shouldFinalizeLogin,
    email,
  }: Partial<CompletedEvent>) {
    if (loggedIn) {
      if (shouldFinalizeLogin) {
        await exchangeLoginCookie(storefrontOrigin, (error) => {
          notify(new Error(error));
        });
      }
    }

    dispatchEvent('completed', {
      loggedIn,
      email,
    });

    setFollowing(true);
    setReceivedUserInteraction(false);
    setFollowingModalVisible(false);
  }

  function handleError({code, email, message}: AuthorizeErrorEvent): void {
    dispatchEvent('error', {
      code,
      email,
      message,
    });

    if (code === 'retriable_server_error') {
      if (error === code) {
        iframeRef.current?.reload();
      }
      setError('retriable_server_error');
    }
  }

  function handleLoaded() {
    if (!following && receivedUserInteraction) {
      iframeRef.current?.open('user_button_clicked');
    }
  }

  const backgroundClassname = classNames(
    'absolute bottom-0 top-0 -z-10 rounded-max bg-purple-primary',
    following ? 'w-9 animate-follow' : 'w-full group-hover_bg-purple-d0',
  );
  const containerClassname = classNames(
    'group relative inline-flex h-9 items-center rounded-max bg-transparent',
    following && 'gap-x-1-5',
  );
  const textClassname = classNames(
    'cursor-pointer whitespace-nowrap pr-3 font-sans text-button-large transition-colors',
    following ? 'text-black' : 'text-white',
  );

  const translationKey = following
    ? 'shopFollowButton.following'
    : 'shopFollowButton.follow';

  const {authorizeUrl} = useAuthorizeUrl({
    clientId,
    error,
    flow: 'follow',
    proxy: true,
    redirectType: 'iframe',
    responseType: 'code',
  });
  const modal = receivedUserInteraction ? (
    <AuthorizeIframe
      onClose={() => setFollowingModalVisible(false)}
      onComplete={handleCompleted}
      onError={handleError}
      onLoaded={handleLoaded}
      proxy
      ref={iframeRef}
      src={authorizeUrl}
      storefrontOrigin={storefrontOrigin}
      variant="follow"
    />
  ) : null;

  const shouldShowFollowingModal =
    following && followingModalVisible && storefrontOrigin;
  const followingModal = shouldShowFollowingModal ? (
    <FollowingModal
      anchorElement={buttonRef}
      devMode={devMode}
      onClose={() => setFollowingModalVisible(false)}
      storefrontOrigin={storefrontOrigin}
    />
  ) : null;

  return (
    <div className="relative z-0">
      <button
        className={containerClassname}
        onClick={handleButtonClick}
        type="button"
        ref={buttonRef}
      >
        <div className={backgroundClassname} />

        <div className="px-2 text-white">
          <HeartIcon className="size-5" filled={following} />
        </div>

        <span className={textClassname}>
          {translate(translationKey, {
            shop: <ShopIcon className="relative inline-block h-4 w-auto" />,
          })}
        </span>
      </button>
      {modal}
      {followingModal}
    </div>
  );
};

const getFeatureDictionary = async (locale: string) =>
  // Temporary adding an await here so that the i18n-dynamic-import-replacer
  // replaces the import statement with the correct file.
  // https://github.com/Shopify/shop-identity/issues/2814
  // eslint-disable-next-line no-return-await
  await import(`./translations/${locale}.json`);

register<ShopFollowButtonProps>(
  ({devMode, element, ...props}) => (
    <RootProvider
      devMode={devMode}
      element={element}
      monorailProps={{
        analyticsContext: 'loginWithShopFollow',
        flow: 'follow',
      }}
      featureName="ShopFollowButton"
      getFeatureDictionary={getFeatureDictionary}
    >
      <FollowInternal {...props} devMode={devMode} />
    </RootProvider>
  ),
  {
    name: 'shop-follow-button',
    props: {
      clientId: 'string',
      devMode: 'boolean',
      storefrontOrigin: 'string',
    },
    shadow: 'open',
  },
);

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace preact.JSX {
    interface IntrinsicElements {
      ['shop-follow-button']: ShopFollowButtonProps;
    }
  }
}

export function ShopFollowButton(props: ShopFollowButtonProps) {
  return <shop-follow-button {...props} />;
}

export default ShopFollowButton;
